<template>
  <a-layout class="layout">
    <a-layout-header class="main-nav">
      <div class="logo">
        <img src="@/assets/senso/logo.png" alt="" />
      </div>
      <a-menu
        v-model="selectedKeys"
        theme="dark"
        mode="horizontal"
        :style="{ lineHeight: '64px' }"
      >
        <a-menu-item key="all">All</a-menu-item>
        <a-menu-item key="screen">Screen</a-menu-item>
        <a-menu-item key="action">Action</a-menu-item>
        <a-menu-item key="operate">Operate</a-menu-item>
        <a-menu-item key="admin">Admin</a-menu-item>
        <a-menu-item key="pinned">Pinned</a-menu-item>
        <a-menu-item key="tsos">teamSOS</a-menu-item>
      </a-menu>
    </a-layout-header>

    <!-- Second nav -->
    <div class="second-nav">
      <div class="search">
        <a-icon type="search" style="color: #fff"></a-icon>
      </div>
      <div class="icon-wrapper">
        <div class="icon" @click.prevent="$router.push('/senso/lockdown')">
          <div class="icon-inner">
            <a-icon type="lock"></a-icon>
          </div>
          <span>Lockdown</span>
        </div>
        <div class="icon">
          <div
            class="icon-inner"
            @click.prevent="$router.push('/senso/announcements')"
          >
            <a-icon type="notification"></a-icon>
          </div>
          <span>Announcements</span>
        </div>
        <div class="icon">
          <div
            class="icon-inner"
            @click.prevent="$router.push('/senso/incidents')"
          >
            <a-icon type="warning"></a-icon>
          </div>
          <span>Incidents</span>
        </div>
        <div class="icon guidance">
          <div
            class="icon-inner"
            @click.prevent="$router.push('/senso/guidance')"
          >
            <a-icon type="book"></a-icon>
          </div>
          <span>Guidance</span>
        </div>
      </div>
    </div>
    <!-- / Second nav -->

    <a-layout-content
      style="
        background: #f4f6fa;
        padding: 24px;
        min-height: 280px;
        overflow-y: scroll;
      "
    >
      <slot />
    </a-layout-content>
  </a-layout>
</template>

<script>
export default {
  data() {
    return {
      selectedKeys: ["tsos"],
    };
  },
};
</script>

<style lang="scss">
#senso-layout {
  background: #f4f6fa;
  height: 100%;

  .form-inner {
    margin-top: 20px;
  }

  .form-last {
    margin-bottom: 10px;
  }

  .ant-btn-primary {
    background: #3398c6 !important;
    border-radius: 5px;

     &:disabled {
        opacity: 0.7;
    }
  }

  .card {
    border: 1px solid #ebedef;
    border-radius: 6px;
    overflow: hidden;

    .nav {
      background: #2d353b;
      display: flex;
      .nav-item {
        color: #fff;
        padding: 15px;
        cursor: pointer;

        &.active {
          font-weight: 500;
        }
      }
    }

    .header {
      background: #2d353b;
      padding: 13px 15px;
      display: flex;
      justify-items: center;
      align-items: center;
      h3 {
        color: #fff;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .search {
      text-align: right;
      display: block;
    }

    .body {
      background: #fff;
      padding: 15px;

      &.bb {
        border-bottom: 1px solid #ebedef;
      }
    }
  }

  .ant-layout-header {
    height: 50px;

    .logo {
      float: left;
      width: 180px;
      height: 50px;
      display: flex;
      align-items: center;

      img {
        height: 17px;
      }
    }

    .ant-menu {
      line-height: 50px !important;

      .ant-menu-item {
        color: #fff;

        &:hover,
        &.ant-menu-item-selected {
          background: #fff !important;
          color: #2d353b;
        }
      }
    }
  }

  .ant-layout-header {
    padding: 0 20px;
  }

  .ant-layout-header,
  .ant-menu.ant-menu-dark {
    background: #2d353b;
  }

  .main-nav {
    z-index: 90;
  }

  .second-nav {
    background: #fff;
    // border-bottom: 1px solid #e3ebf6;
    display: flex;

    box-shadow: 0 3px 10px #888888;
    z-index: 50;

    .search {
      flex-shrink: 1;
      padding: 35px 0;
      background: #3497c6;
      width: 25px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    .icon-wrapper {
      flex-grow: 1;
      display: flex;
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;

      .icon {
        &.guidance {
          margin-left: 15px;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding-right: 15px;

        &:hover {
          cursor: pointer;

          .icon-inner {
            background: #eee;
          }
        }

        font-size: 12px;

        .icon-inner {
          border: 1px #2d353b solid;
          height: 40px;
          width: 40px;
          display: flex;
          align-content: center;
          justify-content: center;
          border-radius: 9px;
          align-items: center;
          justify-items: center;
          margin-bottom: 5px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>